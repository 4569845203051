.sumBox{
    margin: 10px    15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #2d5c97;
    color: #fff;
}

.sumBox-totalSum{
    font-weight: bold;
    font-size: 20px;
}

.sumBox-todaySum {
    margin: 5px;
}

.sumBox-todaySum > span{
    font-weight: bold;
}

.dateSettings{
    display: flex;
    border-bottom: solid 1px #9191917e;
    padding: 0 15px;
}

.calendarIcon{
    height: 20px;
    margin: auto 15px auto 0;
    filter: invert(33%) sepia(96%) saturate(373%) hue-rotate(171deg) brightness(85%) contrast(94%);
}

.payments-box{
    display: flex;
    justify-content: space-between;
    padding: 2px 35px 2px 35px;
}

.payments-box > p{
    padding: 0;
    margin: 0;
}

.incasation-button{
    margin: 0 auto;
    background-color: transparent;
    border: #2d5c97 solid 3px;
    color: #2d5c97;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 20px;
}

.incasation-wrap{
    display: flex;
    margin-top: 20px;
}

.daterangeSelect,
.incasationMenu {
    position: fixed;
    transition: .4s;
    transform: translateY(100%);
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 2px solid #83828250;
    will-change: transform;
}

.payments-wrap-title{
    margin: 0;
}

.today{
    /* background-color: #36c15b; */
    background-color: #FE7235;
}

.periodSum{
    border-bottom: solid 1px #686b6e53;
    padding-bottom: 5px;
    margin-top: 0;
    padding-left: 20px;
}

.daterangeSelect-el{
    border-bottom: 1px solid #83828250;
}


.daterangeSelect-wrap{
    position: fixed;
    bottom: 0; 
    width: 100%; 
    padding: 10px; 
    text-align: center; 
}

.payments-wrap{
    margin: 10px;
    border-radius: 15px;
    padding: 10px;
    /* background: rgb(76,26,87);
    background: linear-gradient(45deg, rgba(76,26,87,1) 0%, rgba(186,176,212,1) 100%); */
    background-color: #0d65c9;
    color: #fff;
    box-shadow: #b6afaf 1px 1px 4px;
}

.payments-box  span{
    font-weight: bold;
    margin-left: auto;
}

.payments-wrap-title{
    font-size: 17px;
    text-align: center;
}

.payments-box-type{
    font-weight: bold;
    font-size: 16px;
}

.payments-box-value > span{
}

.incassation-date{
    font-weight: bold;
    font-size: 19px;
    margin: 0;
}

.incasation-wrap1{
    background-color: #d76363;
}

.daterangeSelect-close{
    background-color: rgb(249, 72, 72);
    color: #fff;
    margin: 0;
}

.daterangeSelect-close > p{
    margin: 0;
    padding: 10px 0;
}

.calendar{
    background-color: #2d5c97;
    z-index: 5;
    transform: translateY(100%);
    align-items: center;
    text-align: center;
    padding-top: 20px;
}

.calendarTitle{
    color: #fff;
    margin: 0;
    margin-bottom: 8px;
}

.calendarInput{
    height: 40px;
    width: 200px;
    border: none;
    margin-bottom: 20px;
    text-align: center;
}

.incasationMenu{
    transform: translateY(100%);
}

.doIncassationButton{
    background-color: rgb(72, 201, 72);
}

.inncasationSelect{
    width: 180px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
}

.closeButton{
    text-align: center;
    font-weight: 600;
    padding: 15px 0;
    font-size: 20px;
}